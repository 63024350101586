import { useRouter } from "next/router";
import Script from "next/script";
import { useEffect } from "react";

export default function Messenger(){
    const router = useRouter();
    useEffect(()=>{
        if(!router.isReady) return;
        (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
        var chatbox = document.getElementById('fb-customer-chat');
            chatbox.setAttribute("page_id", "138525646208492");
            chatbox.setAttribute("attribution", "biz_inbox");
        window.fbAsyncInit = function() {
            FB.init({
                xfbml            : true,
                version          : 'v17.0'
            });
        };
    },[router]);

    return(
        <div>
            <div id="fb-root"></div>
            <div id="fb-customer-chat" className="fb-customerchat"></div>
        </div>
    )
}