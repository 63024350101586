import Head from 'next/head'
import dynamic from 'next/dynamic'
import Messenger from '@/facebook/messenger';
import { useRouter } from 'next/router';
const Nav = dynamic(() => import('@components/Navbar/MainBar'), {
    loading: () => 'Loading...',
});
const Footer = dynamic(() => import('@components/Footer/Main'), {
    loading: () => 'Loading...',
});

const Layout = ({user, pageTitle = "Rapidé", children}) => {
    const router = useRouter();
    return (
        <div className="bg-white">
            <Head>
                <title>{pageTitle}</title>
            </Head>
            <Messenger/>
            <Nav/>
            <main className="max-w-full mx-auto">
                {children}
            </main>
            {router.route != '/checkout' && (
                <Footer/>
            )}
        </div>
    )
}

export default Layout
